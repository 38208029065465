<template>
<metainfo>
</metainfo>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;900&family=Young+Serif&display=swap');
* { box-sizing:border-box; }
body,html { margin:0; padding:0; font-family: 'Inter', sans-serif;
}
#app {
  font-family: 'Inter', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  position:absolute; top:0; left:0;
}


</style>
<script>
import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta(
    { title: 'MTI Brands | Bunker creativo en México', description: 'Bunker creativo especializado en crear valor e impacto a través de herramientas propias en las áreas de Sports Marketing | Fashion Marketing | Lifestyle Marketing | Gamification' }
    )
  }
}
</script>
