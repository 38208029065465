import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager } from 'vue-meta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLinkedinIn,faFacebook,faXTwitter,faInstagram,faYoutube } from '@fortawesome/free-brands-svg-icons'

library.add(faLinkedinIn,faFacebook,faXTwitter,faInstagram,faYoutube)

createApp(App)
.use(router)
.use(createMetaManager())
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
